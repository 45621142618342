const config = require('./config.yaml');

/**
 * Реализует логику варианта base компонента anchor
 */
class CAnchor_base {
	constructor() {
		this.config = config;
	}

	/**
	 * Инициализация
	 */
	init() {
		AR.events.on('svgRenderedAll', () => {
			this.$anchors = $('[data-anchor]');
			this.$anchorsLink = $('[data-anchor-link]');

			if (this.$anchors.length) {
				this.initAnchoring();
			}

			if (this.$anchorsLink.length) {
				this.$anchorsLink.on('click', event => {
					const $elem = $(event.currentTarget);
					const link = $elem.data('anchorLink');
					const linkParts = link.split('#');
					const dataAnchorValue = '#' + linkParts.pop();
					const locationPath = linkParts.shift().replace(new RegExp('/$'), '');
					const pathnameRegex = new RegExp(`^${templateVars.siteUrl.pathname}`);
					const isCurrentPage = ((!locationPath) || pathnameRegex.test(locationPath));
					window.location = link;

					if (this.getAnchor(dataAnchorValue).length && isCurrentPage) {
						this.initAnchoring();
					}
				});
			}
		});
	}

	/**
	 * Инициализация якоря
	 */
	initAnchoring() {
		const $activeAnchor = this.getAnchor();

		if ($activeAnchor.length) {
			this.scrollToElement($activeAnchor);
		}
	}

	/**
	 * Получить якорь
	 * @param {string} hash хэш
	 */
	getAnchor(hash = window.location.hash) {
		if (hash.length) {
			return this.$anchors.filter((i, elem) => $(elem).data('anchor') === hash);
		} else {
			return false;
		}
	}

	/**
	 * скролл к якорю
	 * @param {object} $elem элемент якоря
	 */
	scrollToElement($elem) {
		const topPos = $elem.offset().top;

		$('html, body').velocity('scroll', {
			offset: topPos - this.config.heightFixedHead,
			duration: this.config.scrollDuration,
			begin: () => {
				AR.events.emit('onAnchorLinkScrollStart', $elem);
			},
			complete: () => {
				$elem.addClass('target-anchor');
				AR.events.emit('onAnchorLinkScrollEnd', $elem);
				//триггер глобального события после добавления класса к целевому объекту
				AR.events.emit('onAnchorLinkAnimationStart', $elem);

				setTimeout(() => {
					$elem.removeClass('target-anchor');
					//триггер глобального события после удаления класса у целевого объекта
					AR.events.emit('onAnchorLinkAnimationEnd', $elem);
				}, this.config.removeTargetAnchor);
			}
		});
	}
}

AR.waitComponents([], () => {
	const cAnchor_base = new CAnchor_base();
	// Вызов метода, инициализирующего все существующие события
	cAnchor_base.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cAnchor_base, 'cAnchor_base');
});
