/**
 * Класс реализует базовую логику для компонента anchor
 */
class CAnchor {
	constructor() {

	}
}

module.exports = CAnchor;
