const config = require('./config.yaml');
const CSlider = require('../../index.js');

class CSlider_news extends CSlider {
	constructor() {
		super();
		this.config = config;
	}

	init() {
		this.$sliderWrapper = $(`.js-slider-wrapper--${config.name}`);

		if (this.$sliderWrapper.length) {
			this.initSlider(this.$sliderWrapper);
		}
	}
}

AR.waitComponents(['cPreloader_base'], () => {
	const cSlider_news = new CSlider_news();

	cSlider_news.init();

	AR.pushComponent(cSlider_news, 'cSlider_news');
});
