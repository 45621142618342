module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'tracking/base' && templateVars.config ?
				templateVars.config :
				{
	"printOnlyGoogle": true,
	"systems": {
		"ar": {
			"ru": {
				"yandex": {
					"id": 48756929,
					"trackGoals": true,
					"userVisitedGoalID": "userVisited"
				},
				"google": {
					"id": "UA-118784024-2"
				}
			},
			"en": {
				"yandex": {
					"id": 37315450,
					"trackGoals": true,
					"userVisitedGoalID": "userVisited"
				},
				"google": {
					"id": "GTM-NNQ7FGZ"
				},
				"mail": {
					"id": 2590975
				},
				"leadforensics": {
					"id": 113293
				}
			}
		}
	}
};