module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'slider/main' && templateVars.config ?
				templateVars.config :
				{
	"name": "main",
	"containerMinHeight": "auto",
	"options": {
		"direction": "vertical",
		"speed": 1000,
		"loop": false,
		"effect": "slide",
		"fadeEffect": {
			"crossFade": true
		},
		"pagination": {
			"el": ".js-slider-pagination",
			"type": "bullets",
			"bulletElement": "span",
			"clickable": true,
			"render": true
		},
		"navigation": false,
		"autoplay": {
			"delay": 15000,
			"disableOnInteraction": false,
			"stopOnLastSlide": true
		},
		"initialSlide": 0,
		"simulateTouch": false,
		"keyboard": {
			"enabled": true,
			"onlyInViewport": true
		},
		"mousewheel": true,
		"nested": true,
		"runCallbacksOnInit": false,
		"hashNavigation": false,
		"preventInteractionOnTransition": true,
		"parallax": false,
		"followFinger": true
	},
	"subSlider": {
		"sliderClass": ".js-sub-slider",
		"options": {
			"effect": "fade",
			"autoplay": {
				"delay": 4000
			}
		}
	}
};