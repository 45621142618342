module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'projects/clients' && templateVars.config ?
				templateVars.config :
				{
	"scrollTopOnChange": true,
	"minForScrollTop": 500,
	"sortItems": true,
	"gridParams": {
		"class": "col-clients-custom col-md-6 col-sm-12"
	},
	"breakpoints": {
		"lg": 1270,
		"md": 1054,
		"sm": 798,
		"xs": 510
	},
	"groups": [
		{
			"id": "largest",
			"filter": [
				"largest"
			],
			"class": "is-active",
			"title": {
				"ru": "Крупнейшие",
				"en": "Крупнейшие"
			}
		},
		{
			"id": "all",
			"filter": [],
			"title": {
				"ru": "Все клиенты",
				"en": "Все клиенты"
			}
		},
		{
			"id": "agricultural-sector",
			"filter": [
				"agricultural-sector"
			],
			"title": {
				"ru": "Аграрный сектор",
				"en": "Аграрный сектор"
			}
		},
		{
			"id": "atomic-sector",
			"filter": [
				"atomic-sector"
			],
			"title": {
				"ru": "Атомный сектор",
				"en": "Атомный сектор"
			}
		},
		{
			"id": "it",
			"filter": [
				"it"
			],
			"title": {
				"ru": "Инновации, IT и телеком",
				"en": "Инновации, IT и телеком"
			}
		},
		{
			"id": "non-profit-organizations",
			"filter": [
				"non-profit-organizations"
			],
			"title": {
				"ru": "Институты развития и некоммерческие организации",
				"en": "Институты развития и некоммерческие организации"
			}
		},
		{
			"id": "metallurgy",
			"filter": [
				"metallurgy"
			],
			"title": {
				"ru": "Металлургия и добывающая промышленность",
				"en": "Металлургия и добывающая промышленность"
			}
		},
		{
			"id": "oil-and-gas",
			"filter": [
				"oil-and-gas"
			],
			"title": {
				"ru": "Нефть и газ",
				"en": "Нефть и газ"
			}
		},
		{
			"id": "consumer-sector",
			"filter": [
				"consumer-sector"
			],
			"title": {
				"ru": "Потребительский сектор",
				"en": "Потребительский сектор"
			}
		},
		{
			"id": "industry",
			"filter": [
				"industry"
			],
			"title": {
				"ru": "Промышленность, инжиниринг и машиностроение",
				"en": "Промышленность, инжиниринг и машиностроение"
			}
		},
		{
			"id": "building",
			"filter": [
				"building"
			],
			"title": {
				"ru": "Строительство и недвижимость",
				"en": "Строительство и недвижимость"
			}
		},
		{
			"id": "logistics",
			"filter": [
				"logistics"
			],
			"title": {
				"ru": "Транспорт и логистика",
				"en": "Транспорт и логистика"
			}
		},
		{
			"id": "financial-sector",
			"filter": [
				"financial-sector"
			],
			"title": {
				"ru": "Финансовый сектор и банки",
				"en": "Финансовый сектор и банки"
			}
		},
		{
			"id": "power-engineering",
			"filter": [
				"power-engineering"
			],
			"title": {
				"ru": "Энергетика и сетевые компании",
				"en": "Энергетика и сетевые компании"
			}
		}
	],
	"items": [
		{
			"id": 1,
			"title": {
				"ru": "Лукойл",
				"en": "Lukoil"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"largest",
				"oil-and-gas"
			],
			"images": {
				"url": "/images/content/ru/clients/lukoil_wb.svg"
			}
		},
		{
			"id": 2,
			"title": {
				"ru": "Роснефть",
				"en": "Rosneft"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"largest",
				"oil-and-gas"
			],
			"images": {
				"url": "/images/content/ru/clients/rosneft_wb.svg"
			}
		},
		{
			"id": 3,
			"title": {
				"ru": "Сбербанк",
				"en": "Sberbank"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"largest",
				"financial-sector"
			],
			"images": {
				"url": "/images/content/ru/clients/sberbank_wb.svg"
			}
		},
		{
			"id": 4,
			"title": {
				"ru": "РЖД",
				"en": "RZD"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"largest",
				"logistics"
			],
			"images": {
				"url": "/images/content/ru/clients/rzd_wb.svg"
			}
		},
		{
			"id": 5,
			"title": {
				"ru": "Газпром нефть",
				"en": "Газпром нефть"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"largest",
				"oil-and-gas"
			],
			"images": {
				"url": "/images/content/ru/clients/gazpromneft_wb.svg"
			}
		},
		{
			"id": 6,
			"title": {
				"ru": "Норникель",
				"en": "Норникель"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"largest",
				"metallurgy"
			],
			"images": {
				"url": "/images/content/ru/clients/nornikel_wb.svg"
			}
		},
		{
			"id": 7,
			"title": {
				"ru": "ВТБ",
				"en": "VTB"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"largest",
				"financial-sector"
			],
			"images": {
				"url": "/images/content/ru/clients/vtb_wb.svg"
			}
		},
		{
			"id": 8,
			"title": {
				"ru": "Транснефть",
				"en": "Транснефть"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"largest",
				"oil-and-gas",
				"logistics"
			],
			"images": {
				"url": "/images/content/ru/clients/transneft_wb.svg"
			}
		},
		{
			"id": 9,
			"title": {
				"ru": "X5 Retail Group",
				"en": "X5 Retail Group"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"largest",
				"consumer-sector"
			],
			"images": {
				"url": "/images/content/ru/clients/x5_wb.svg"
			}
		},
		{
			"id": 10,
			"title": {
				"ru": "Евраз",
				"en": "Evraz"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"largest",
				"metallurgy"
			],
			"images": {
				"url": "/images/content/ru/clients/evraz_wb.svg"
			}
		},
		{
			"id": 11,
			"title": {
				"ru": "РусГидро",
				"en": "RusHydro"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"largest",
				"power-engineering"
			],
			"images": {
				"url": "/images/content/ru/clients/rushydro_wb.svg"
			}
		},
		{
			"id": 12,
			"title": {
				"ru": "Полиметалл",
				"en": "Полиметалл"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"largest",
				"metallurgy"
			],
			"images": {
				"url": "/images/content/ru/clients/polymetal_wb.svg"
			}
		},
		{
			"id": 16,
			"title": {
				"ru": "AvangardCo",
				"en": "AvangardCo"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"agricultural-sector"
			],
			"images": {
				"url": "/images/content/ru/clients/avangardco_wb.svg"
			}
		},
		{
			"id": 17,
			"title": {
				"ru": "Мираторг",
				"en": "Мираторг"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"consumer-sector",
				"agricultural-sector"
			],
			"images": {
				"url": "/images/content/ru/clients/miratorg_wb.svg"
			}
		},
		{
			"id": 18,
			"title": {
				"ru": "Русагро",
				"en": "Русагро"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"consumer-sector",
				"agricultural-sector"
			],
			"images": {
				"url": "/images/content/ru/clients/rusagro_wb.svg"
			}
		},
		{
			"id": 19,
			"title": {
				"ru": "УкрЛендФарминг",
				"en": "УкрЛендФарминг"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"agricultural-sector"
			],
			"images": {
				"url": "/images/content/ru/clients/ukrlanfarming_wb.svg"
			}
		},
		{
			"id": 20,
			"title": {
				"ru": "Черкизово",
				"en": "Черкизово"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"consumer-sector",
				"agricultural-sector"
			],
			"images": {
				"url": "/images/content/ru/clients/cherkizovo_wb.svg"
			}
		},
		{
			"id": 21,
			"title": {
				"ru": "Атомэнергопроект",
				"en": "Атомэнергопроект"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"atomic-sector"
			],
			"images": {
				"url": "/images/content/ru/clients/atomenergoproekt_wb.svg"
			}
		},
		{
			"id": 22,
			"title": {
				"ru": "ЗИО Подольск",
				"en": "ЗИО Подольск"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"atomic-sector",
				"industry"
			],
			"images": {
				"url": "/images/content/ru/clients/ziopodolsk_wb.svg"
			}
		},
		{
			"id": 23,
			"title": {
				"ru": "Зиомар",
				"en": "Зиомар"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"atomic-sector",
				"industry"
			],
			"images": {
				"url": "/images/content/ru/clients/ziomar_wb.svg"
			}
		},
		{
			"id": 24,
			"title": {
				"ru": "Росатом",
				"en": "Росатом"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"atomic-sector",
				"power-engineering"
			],
			"images": {
				"url": "/images/content/ru/clients/rosatom_wb.svg"
			}
		},
		{
			"id": 25,
			"title": {
				"ru": "Росэнергоатом",
				"en": "Росэнергоатом"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"atomic-sector",
				"power-engineering"
			],
			"images": {
				"url": "/images/content/ru/clients/rosenergoatom_wb.svg"
			}
		},
		{
			"id": 26,
			"title": {
				"ru": "Техснабэкспорт",
				"en": "Техснабэкспорт"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"atomic-sector"
			],
			"images": {
				"url": "/images/content/ru/clients/techsnabexport_wb.svg"
			}
		},
		{
			"id": 27,
			"title": {
				"ru": "IBS",
				"en": "IBS"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"it"
			],
			"images": {
				"url": "/images/content/ru/clients/ibs_wb.svg"
			}
		},
		{
			"id": 28,
			"title": {
				"ru": "Газпром нефть НТЦ",
				"en": "Газпром нефть НТЦ"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"it",
				"oil-and-gas",
				"industry"
			],
			"images": {
				"url": "/images/content/ru/clients/gpnntc_wb.svg"
			}
		},
		{
			"id": 29,
			"title": {
				"ru": "Ростелеком",
				"en": "Ростелеком"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"it",
				"consumer-sector"
			],
			"images": {
				"url": "/images/content/ru/clients/rtk_wb.svg"
			}
		},
		{
			"id": 30,
			"title": {
				"ru": "Сколково",
				"en": "Сколково"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"it",
				"non-profit-organizations"
			],
			"images": {
				"url": "/images/content/ru/clients/skolkovo_wb.svg"
			}
		},
		{
			"id": 31,
			"title": {
				"ru": "ЦРПТ",
				"en": "ЦРПТ"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"it"
			],
			"images": {
				"url": "/images/content/ru/clients/crt_wb.svg"
			}
		},
		{
			"id": 32,
			"title": {
				"ru": "Агентство по страхованию вкладов",
				"en": "Агентство по страхованию вкладов"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"non-profit-organizations",
				"financial-sector"
			],
			"images": {
				"url": "/images/content/ru/clients/vkladagents_wb.svg"
			}
		},
		{
			"id": 33,
			"title": {
				"ru": "АРФИ",
				"en": "АРФИ"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"non-profit-organizations"
			],
			"images": {
				"url": "/images/content/ru/clients/arfi_wb.svg"
			}
		},
		{
			"id": 34,
			"title": {
				"ru": "ВЭБ",
				"en": "ВЭБ"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"non-profit-organizations",
				"financial-sector"
			],
			"images": {
				"url": "/images/content/ru/clients/web_wb.svg"
			}
		},
		{
			"id": 35,
			"title": {
				"ru": "МСП банк",
				"en": "МСП банк"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"non-profit-organizations",
				"financial-sector"
			],
			"images": {
				"url": "/images/content/ru/clients/mspbank_wb.svg"
			}
		},
		{
			"id": 36,
			"title": {
				"ru": "РВК",
				"en": "РВК"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"non-profit-organizations",
				"financial-sector"
			],
			"images": {
				"url": "/images/content/ru/clients/rvk_wb.svg"
			}
		},
		{
			"id": 37,
			"title": {
				"ru": "Фонд развития Дальнего Востока",
				"en": "Фонд развития Дальнего Востока"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"non-profit-organizations",
				"financial-sector"
			],
			"images": {
				"url": "/images/content/ru/clients/fondrazvityavostoka_wb.svg"
			}
		},
		{
			"id": 38,
			"title": {
				"ru": "FSC",
				"en": "FSC"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"non-profit-organizations"
			],
			"images": {
				"url": "/images/content/ru/clients/fsc_wb.svg"
			}
		},
		{
			"id": 39,
			"title": {
				"ru": "Фонд Паллиативной помощи детям",
				"en": "Фонд Паллиативной помощи детям"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"non-profit-organizations"
			],
			"images": {
				"url": "/images/content/ru/clients/fondchildrenhelp_wb.svg"
			}
		},
		{
			"id": 40,
			"title": {
				"ru": "ОПРФ",
				"en": "ОПРФ"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"non-profit-organizations"
			],
			"images": {
				"url": "/images/content/ru/clients/oprf_wb.svg"
			}
		},
		{
			"id": 41,
			"title": {
				"ru": "Фонд Егора Гайдара",
				"en": "Фонд Егора Гайдара"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"non-profit-organizations"
			],
			"images": {
				"url": "/images/content/ru/clients/gaidar_wb.svg"
			}
		},
		{
			"id": 42,
			"title": {
				"ru": "Акрон",
				"en": "Акрон"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"metallurgy"
			],
			"images": {
				"url": "/images/content/ru/clients/akron_wb.svg"
			}
		},
		{
			"id": 43,
			"title": {
				"ru": "Алроса",
				"en": "Алроса"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"metallurgy"
			],
			"images": {
				"url": "/images/content/ru/clients/alrosa_wb.svg"
			}
		},
		{
			"id": 44,
			"title": {
				"ru": "Высочайший",
				"en": "Высочайший"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"metallurgy"
			],
			"images": {
				"url": "/images/content/ru/clients/visochaishiy_wb.svg"
			}
		},
		{
			"id": 45,
			"title": {
				"ru": "Кузбасская топливная компания",
				"en": "Кузбасская топливная компания"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"metallurgy"
			],
			"images": {
				"url": "/images/content/ru/clients/kuzbas_wb.svg"
			}
		},
		{
			"id": 46,
			"title": {
				"ru": "Металлоинвест",
				"en": "Металлоинвест"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"metallurgy"
			],
			"images": {
				"url": "/images/content/ru/clients/metalloinvest_wb.svg"
			}
		},
		{
			"id": 47,
			"title": {
				"ru": "СУЭК",
				"en": "СУЭК"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"metallurgy"
			],
			"images": {
				"url": "/images/content/ru/clients/suek_wb.svg"
			}
		},
		{
			"id": 48,
			"title": {
				"ru": "Уралкалий",
				"en": "Уралкалий"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"metallurgy"
			],
			"images": {
				"url": "/images/content/ru/clients/uralkalii_wb.svg"
			}
		},
		{
			"id": 49,
			"title": {
				"ru": "Уралхим",
				"en": "Уралхим"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"metallurgy"
			],
			"images": {
				"url": "/images/content/ru/clients/uralhim_wb.svg"
			}
		},
		{
			"id": 50,
			"title": {
				"ru": "Фосагро",
				"en": "Фосагро"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"metallurgy"
			],
			"images": {
				"url": "/images/content/ru/clients/fosargo_wb.svg"
			}
		},
		{
			"id": 51,
			"title": {
				"ru": "Газпром нефть Шельф",
				"en": "Газпром нефть Шельф"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"oil-and-gas"
			],
			"images": {
				"url": "/images/content/ru/clients/gpnshelf_wb.svg"
			}
		},
		{
			"id": 52,
			"title": {
				"ru": "Weatherford",
				"en": "Weatherford"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"oil-and-gas"
			],
			"images": {
				"url": "/images/content/ru/clients/weatherford_wb.svg"
			}
		},
		{
			"id": 53,
			"title": {
				"ru": "Геотек",
				"en": "Геотек"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"oil-and-gas"
			],
			"images": {
				"url": "/images/content/ru/clients/geotek_wb.svg"
			}
		},
		{
			"id": 54,
			"title": {
				"ru": "Зарубежнефть",
				"en": "Зарубежнефть"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"oil-and-gas"
			],
			"images": {
				"url": "/images/content/ru/clients/zarubegneft_wb.svg"
			}
		},
		{
			"id": 55,
			"title": {
				"ru": "Нижнекамскнефтехим",
				"en": "Нижнекамскнефтехим"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"oil-and-gas",
				"industry"
			],
			"images": {
				"url": "/images/content/ru/clients/nizhenahtehim_wb.svg"
			}
		},
		{
			"id": 56,
			"title": {
				"ru": "Русснефть",
				"en": "Русснефть"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"oil-and-gas"
			],
			"images": {
				"url": "/images/content/ru/clients/russneft_wb.svg"
			}
		},
		{
			"id": 57,
			"title": {
				"ru": "ТНК-ВР",
				"en": "ТНК-ВР"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"oil-and-gas"
			],
			"images": {
				"url": "/images/content/ru/clients/tnkvr_wb.svg"
			}
		},
		{
			"id": 58,
			"title": {
				"ru": "ЯТЭК",
				"en": "ЯТЭК"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"oil-and-gas"
			],
			"images": {
				"url": "/images/content/ru/clients/yatek_wb.svg"
			}
		},
		{
			"id": 59,
			"title": {
				"ru": "Coca Cola Hellenic Russia",
				"en": "Coca Cola Hellenic Russia"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"consumer-sector"
			],
			"images": {
				"url": "/images/content/ru/clients/cola_wb.svg"
			}
		},
		{
			"id": 60,
			"title": {
				"ru": "FixPrice",
				"en": "FixPrice"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"consumer-sector"
			],
			"images": {
				"url": "/images/content/ru/clients/fixprice_wb.svg"
			}
		},
		{
			"id": 61,
			"title": {
				"ru": "Heineken Russia",
				"en": "Heineken Russia"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"consumer-sector"
			],
			"images": {
				"url": "/images/content/ru/clients/heinekenrussia_wb.svg"
			}
		},
		{
			"id": 62,
			"title": {
				"ru": "Алвиса",
				"en": "Алвиса"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"consumer-sector"
			],
			"images": {
				"url": "/images/content/ru/clients/alvisa_wb.svg"
			}
		},
		{
			"id": 63,
			"title": {
				"ru": "Мать и дитя",
				"en": "Мать и дитя"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"consumer-sector"
			],
			"images": {
				"url": "/images/content/ru/clients/motherchild_wb.svg"
			}
		},
		{
			"id": 64,
			"title": {
				"ru": "Протек",
				"en": "Протек"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"consumer-sector"
			],
			"images": {
				"url": "/images/content/ru/clients/protek_wb.svg"
			}
		},
		{
			"id": 65,
			"title": {
				"ru": "Русское море",
				"en": "Русское море"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"consumer-sector"
			],
			"images": {
				"url": "/images/content/ru/clients/russea_wb.svg"
			}
		},
		{
			"id": 66,
			"title": {
				"ru": "РЖД Федеральная Пассажирская Компания",
				"en": "РЖД Федеральная Пассажирская Компания"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"consumer-sector",
				"logistics"
			],
			"images": {
				"url": "/images/content/ru/clients/fpc_wb.svg"
			}
		},
		{
			"id": 67,
			"title": {
				"ru": "Газпром ОКДЦ",
				"en": "Газпром ОКДЦ"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"consumer-sector"
			],
			"images": {
				"url": "/images/content/ru/clients/gpnmedic_wb.svg"
			}
		},
		{
			"id": 68,
			"title": {
				"ru": "Airbus Helicopters Vostok",
				"en": "Airbus Helicopters Vostok"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"industry"
			],
			"images": {
				"url": "/images/content/ru/clients/airbusheli_wb.svg"
			}
		},
		{
			"id": 69,
			"title": {
				"ru": "FIAT",
				"en": "FIAT"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"industry"
			],
			"images": {
				"url": "/images/content/ru/clients/fiat_wb.svg"
			}
		},
		{
			"id": 70,
			"title": {
				"ru": "ВРК 1",
				"en": "ВРК 1"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"industry"
			],
			"images": {
				"url": "/images/content/ru/clients/vrk1_wb.svg"
			}
		},
		{
			"id": 71,
			"title": {
				"ru": "ГМС",
				"en": "ГМС"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"industry"
			],
			"images": {
				"url": "/images/content/ru/clients/gms_wb.svg"
			}
		},
		{
			"id": 72,
			"title": {
				"ru": "Иркут",
				"en": "Иркут"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"industry"
			],
			"images": {
				"url": "/images/content/ru/clients/irkut_wb.svg"
			}
		},
		{
			"id": 73,
			"title": {
				"ru": "Калашников",
				"en": "Калашников"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"industry"
			],
			"images": {
				"url": "/images/content/ru/clients/kalash_wb.svg"
			}
		},
		{
			"id": 74,
			"title": {
				"ru": "Камаз",
				"en": "Камаз"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"industry"
			],
			"images": {
				"url": "/images/content/ru/clients/kamaz_wb.svg"
			}
		},
		{
			"id": 75,
			"title": {
				"ru": "Коломенский завод",
				"en": "Коломенский завод"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"industry"
			],
			"images": {
				"url": "/images/content/ru/clients/kolomzavod_wb.svg"
			}
		},
		{
			"id": 76,
			"title": {
				"ru": "ОАК",
				"en": "ОАК"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"industry"
			],
			"images": {
				"url": "/images/content/ru/clients/oak_wb.svg"
			}
		},
		{
			"id": 77,
			"title": {
				"ru": "ОВК",
				"en": "ОВК"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"industry",
				"logistics"
			],
			"images": {
				"url": "/images/content/ru/clients/ovk_wb.svg"
			}
		},
		{
			"id": 78,
			"title": {
				"ru": "ОДК",
				"en": "ОДК"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"industry"
			],
			"images": {
				"url": "/images/content/ru/clients/odk_wb.svg"
			}
		},
		{
			"id": 79,
			"title": {
				"ru": "ОМЗ",
				"en": "ОМЗ"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"industry"
			],
			"images": {
				"url": "/images/content/ru/clients/omz_wb.svg"
			}
		},
		{
			"id": 80,
			"title": {
				"ru": "Оргэнергострой",
				"en": "Оргэнергострой"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"industry"
			],
			"images": {
				"url": "/images/content/ru/clients/orgenergo_wb.svg"
			}
		},
		{
			"id": 81,
			"title": {
				"ru": "ОСК",
				"en": "ОСК"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"industry"
			],
			"images": {
				"url": "/images/content/ru/clients/osk_wb.svg"
			}
		},
		{
			"id": 82,
			"title": {
				"ru": "РПКБ",
				"en": "РПКБ"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"industry"
			],
			"images": {
				"url": "/images/content/ru/clients/rpkb_wb.svg"
			}
		},
		{
			"id": 83,
			"title": {
				"ru": "Соллерс",
				"en": "Соллерс"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"industry"
			],
			"images": {
				"url": "/images/content/ru/clients/sollers_wb.svg"
			}
		},
		{
			"id": 84,
			"title": {
				"ru": "Сухой",
				"en": "Сухой"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"industry"
			],
			"images": {
				"url": "/images/content/ru/clients/sukhoi_wb.svg"
			}
		},
		{
			"id": 85,
			"title": {
				"ru": "ТМК",
				"en": "ТМК"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"industry"
			],
			"images": {
				"url": "/images/content/ru/clients/tmk_wb.svg"
			}
		},
		{
			"id": 86,
			"title": {
				"ru": "Транснефть Диаскан",
				"en": "Транснефть Диаскан"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"industry"
			],
			"images": {
				"url": "/images/content/ru/clients/transneftdia_wb.svg"
			}
		},
		{
			"id": 87,
			"title": {
				"ru": "Трест Гидромонтаж",
				"en": "Трест Гидромонтаж"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"industry",
				"building"
			],
			"images": {
				"url": "/images/content/ru/clients/trestgidromontag_wb.svg"
			}
		},
		{
			"id": 88,
			"title": {
				"ru": "УАЗ",
				"en": "УАЗ"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"industry"
			],
			"images": {
				"url": "/images/content/ru/clients/uaz_wb.svg"
			}
		},
		{
			"id": 89,
			"title": {
				"ru": "А101",
				"en": "А101"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"building"
			],
			"images": {
				"url": "/images/content/ru/clients/a101_wb.svg"
			}
		},
		{
			"id": 90,
			"title": {
				"ru": "Интеко",
				"en": "Интеко"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"building"
			],
			"images": {
				"url": "/images/content/ru/clients/inteko_wb.svg"
			}
		},
		{
			"id": 91,
			"title": {
				"ru": "Мосметрострой",
				"en": "Мосметрострой"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"building"
			],
			"images": {
				"url": "/images/content/ru/clients/mosmetrostroi_wb.svg"
			}
		},
		{
			"id": 92,
			"title": {
				"ru": "Мостотрест",
				"en": "Мостотрест"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"building"
			],
			"images": {
				"url": "/images/content/ru/clients/mostotrest_wb.svg"
			}
		},
		{
			"id": 93,
			"title": {
				"ru": "Олимпстрой",
				"en": "Олимпстрой"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"building"
			],
			"images": {
				"url": "/images/content/ru/clients/olympstroi_wb.svg"
			}
		},
		{
			"id": 94,
			"title": {
				"ru": "ПИК",
				"en": "ПИК"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"building"
			],
			"images": {
				"url": "/images/content/ru/clients/pik_wb.svg"
			}
		},
		{
			"id": 95,
			"title": {
				"ru": "Эталон",
				"en": "Эталон"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"building"
			],
			"images": {
				"url": "/images/content/ru/clients/etalon_wb.svg"
			}
		},
		{
			"id": 96,
			"title": {
				"ru": "AirAstana",
				"en": "AirAstana"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"logistics"
			],
			"images": {
				"url": "/images/content/ru/clients/airastana_wb.svg"
			}
		},
		{
			"id": 97,
			"title": {
				"ru": "AirBridgeCargo",
				"en": "AirBridgeCargo"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"logistics"
			],
			"images": {
				"url": "/images/content/ru/clients/airbridgecargo_wb.svg"
			}
		},
		{
			"id": 98,
			"title": {
				"ru": "EastComtrans",
				"en": "EastComtrans"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"logistics"
			],
			"images": {
				"url": "/images/content/ru/clients/eastcomtrans_wb.svg"
			}
		},
		{
			"id": 99,
			"title": {
				"ru": "FESCO",
				"en": "FESCO"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"logistics"
			],
			"images": {
				"url": "/images/content/ru/clients/fesco_wb.svg"
			}
		},
		{
			"id": 100,
			"title": {
				"ru": "Globaltrans",
				"en": "Globaltrans"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"logistics"
			],
			"images": {
				"url": "/images/content/ru/clients/globaltrans_wb.svg"
			}
		},
		{
			"id": 101,
			"title": {
				"ru": "Атран",
				"en": "Атран"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"logistics"
			],
			"images": {
				"url": "/images/content/ru/clients/atran_wb.svg"
			}
		},
		{
			"id": 101,
			"title": {
				"ru": "Аэрофлот",
				"en": "Аэрофлот"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"logistics"
			],
			"images": {
				"url": "/images/content/ru/clients/aeroflot_wb.svg"
			}
		},
		{
			"id": 102,
			"title": {
				"ru": "Волга-Днепр",
				"en": "Волга-Днепр"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"logistics"
			],
			"images": {
				"url": "/images/content/ru/clients/volga_dnepr_wb.svg"
			}
		},
		{
			"id": 103,
			"title": {
				"ru": "Делопортс",
				"en": "Делопортс"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"logistics"
			],
			"images": {
				"url": "/images/content/ru/clients/deloports_wb.svg"
			}
		},
		{
			"id": 104,
			"title": {
				"ru": "Московский транспорт",
				"en": "Московский транспорт"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"logistics",
				"non-profit-organizations"
			],
			"images": {
				"url": "/images/content/ru/clients/deptransmos_wb.svg"
			}
		},
		{
			"id": 105,
			"title": {
				"ru": "НМТП",
				"en": "НМТП"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"logistics"
			],
			"images": {
				"url": "/images/content/ru/clients/nmtp_wb.svg"
			}
		},
		{
			"id": 106,
			"title": {
				"ru": "ОРВД",
				"en": "ОРВД"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"logistics"
			],
			"images": {
				"url": "/images/content/ru/clients/orvd_wb.svg"
			}
		},
		{
			"id": 107,
			"title": {
				"ru": "Трансгарант",
				"en": "Трансгарант"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"logistics"
			],
			"images": {
				"url": "/images/content/ru/clients/transgarant_wb.svg"
			}
		},
		{
			"id": 108,
			"title": {
				"ru": "Трансконтейнер",
				"en": "Трансконтейнер"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"logistics"
			],
			"images": {
				"url": "/images/content/ru/clients/transcontainer_wb.svg"
			}
		},
		{
			"id": 109,
			"title": {
				"ru": "Уникон",
				"en": "Уникон"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"logistics"
			],
			"images": {
				"url": "/images/content/ru/clients/unikon_wb.svg"
			}
		},
		{
			"id": 110,
			"title": {
				"ru": "Бинбанк",
				"en": "Бинбанк"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"financial-sector"
			],
			"images": {
				"url": "/images/content/ru/clients/binbank_wb.svg"
			}
		},
		{
			"id": 111,
			"title": {
				"ru": "Абсолютбанк",
				"en": "Абсолютбанк"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"financial-sector"
			],
			"images": {
				"url": "/images/content/ru/clients/absolutbank_wb.svg"
			}
		},
		{
			"id": 112,
			"title": {
				"ru": "Возрождение",
				"en": "Возрождение"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"financial-sector"
			],
			"images": {
				"url": "/images/content/ru/clients/bankvozrozdenie_wb.svg"
			}
		},
		{
			"id": 113,
			"title": {
				"ru": "ДельтаКредит",
				"en": "ДельтаКредит"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"financial-sector"
			],
			"images": {
				"url": "/images/content/ru/clients/bankdeltacredit_wb.svg"
			}
		},
		{
			"id": 114,
			"title": {
				"ru": "Открытие",
				"en": "Открытие"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"financial-sector"
			],
			"images": {
				"url": "/images/content/ru/clients/fcotkritie_wb.svg"
			}
		},
		{
			"id": 116,
			"title": {
				"ru": "Газпромбанк",
				"en": "Газпромбанк"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"financial-sector"
			],
			"images": {
				"url": "/images/content/ru/clients/gazprombank_wb.svg"
			}
		},
		{
			"id": 117,
			"title": {
				"ru": "КапиталЪ",
				"en": "КапиталЪ"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"financial-sector"
			],
			"images": {
				"url": "/images/content/ru/clients/capitalJ_wb.svg"
			}
		},
		{
			"id": 118,
			"title": {
				"ru": "Московская Биржа",
				"en": "Московская Биржа"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"financial-sector"
			],
			"images": {
				"url": "/images/content/ru/clients/mosbirzha_wb.svg"
			}
		},
		{
			"id": 119,
			"title": {
				"ru": "Новикомбанк",
				"en": "Новикомбанк"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"financial-sector"
			],
			"images": {
				"url": "/images/content/ru/clients/novikombank_wb.svg"
			}
		},
		{
			"id": 120,
			"title": {
				"ru": "Росгосстрах",
				"en": "Росгосстрах"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"financial-sector"
			],
			"images": {
				"url": "/images/content/ru/clients/rosgorstrah_wb.svg"
			}
		},
		{
			"id": 121,
			"title": {
				"ru": "Русславб анк",
				"en": "Русславбанк"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"financial-sector"
			],
			"images": {
				"url": "/images/content/ru/clients/russlavbank_wb.svg"
			}
		},
		{
			"id": 122,
			"title": {
				"ru": "Сафмар",
				"en": "Сафмар"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"financial-sector"
			],
			"images": {
				"url": "/images/content/ru/clients/safmar_wb.svg"
			}
		},
		{
			"id": 123,
			"title": {
				"ru": "Уралсиб",
				"en": "Уралсиб"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"financial-sector"
			],
			"images": {
				"url": "/images/content/ru/clients/uralsib_wb.svg"
			}
		},
		{
			"id": 124,
			"title": {
				"ru": "Кузбассэнерго",
				"en": "Кузбассэнерго"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"power-engineering"
			],
			"images": {
				"url": "/images/content/ru/clients/kuzbasenergo_wb.svg"
			}
		},
		{
			"id": 125,
			"title": {
				"ru": "Россети Ленэнерго",
				"en": "Россети Ленэнерго"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"power-engineering"
			],
			"images": {
				"url": "/images/content/ru/clients/lenenergo_wb.svg"
			}
		},
		{
			"id": 126,
			"title": {
				"ru": "Мосэнерго",
				"en": "Мосэнерго"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"power-engineering"
			],
			"images": {
				"url": "/images/content/ru/clients/mosenergo_wb.svg"
			}
		},
		{
			"id": 126,
			"title": {
				"ru": "Россети Московский Регион",
				"en": "Россети Московский Регион"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"power-engineering"
			],
			"images": {
				"url": "/images/content/ru/clients/moesk_wb.svg"
			}
		},
		{
			"id": 127,
			"title": {
				"ru": "Россети Северо-Запад",
				"en": "Россети Северо-Запад"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"power-engineering"
			],
			"images": {
				"url": "/images/content/ru/clients/mrsknorthwest_wb.svg"
			}
		},
		{
			"id": 128,
			"title": {
				"ru": "Россети Урал",
				"en": "Россети Урал"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"power-engineering"
			],
			"images": {
				"url": "/images/content/ru/clients/mrskural_wb.svg"
			}
		},
		{
			"id": 129,
			"title": {
				"ru": "Россети Центр",
				"en": "Россети Центр"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"power-engineering"
			],
			"images": {
				"url": "/images/content/ru/clients/mrskcenter_wb.svg"
			}
		},
		{
			"id": 130,
			"title": {
				"ru": "Россети Центр и Приволжье",
				"en": "Россети Центр и Приволжье"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"power-engineering"
			],
			"images": {
				"url": "/images/content/ru/clients/mrsk.svg"
			}
		},
		{
			"id": 131,
			"title": {
				"ru": "Россети Юг",
				"en": "Россети Юг"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"power-engineering"
			],
			"images": {
				"url": "/images/content/ru/clients/mrsksuth_wb.svg"
			}
		},
		{
			"id": 132,
			"title": {
				"ru": "Россети",
				"en": "Россети"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"power-engineering"
			],
			"images": {
				"url": "/images/content/ru/clients/rosseti_wb.svg"
			}
		},
		{
			"id": 133,
			"title": {
				"ru": "Россети ФСК ЕЭС",
				"en": "Россети ФСК ЕЭС"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"power-engineering"
			],
			"images": {
				"url": "/images/content/ru/clients/fskees_wb.svg"
			}
		},
		{
			"id": 134,
			"title": {
				"ru": "En+",
				"en": "En+"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"power-engineering",
				"metallurgy"
			],
			"images": {
				"url": "/images/content/ru/clients/Enplus.svg"
			}
		},
		{
			"id": 135,
			"title": {
				"ru": "Магнит",
				"en": "Магнит"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"consumer-sector"
			],
			"images": {
				"url": "/images/content/ru/clients/Magnit_new.svg"
			}
		},
		{
			"id": 136,
			"title": {
				"ru": "ERG Metallurgy",
				"en": "ERG Metallurgy"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"metallurgy"
			],
			"images": {
				"url": "/images/content/ru/clients/ERG.svg"
			}
		},
		{
			"id": 137,
			"title": {
				"ru": "KASE",
				"en": "KASE"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"financial-sector"
			],
			"images": {
				"url": "/images/content/ru/clients/Kase2.svg"
			}
		},
		{
			"id": 138,
			"title": {
				"ru": "TBC Bank",
				"en": "TBC Bank"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"financial-sector"
			],
			"images": {
				"url": "/images/content/ru/clients/TBC.svg"
			}
		},
		{
			"id": 139,
			"title": {
				"ru": "Европейская Электротехника",
				"en": "Европейская Электротехника"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"industry"
			],
			"images": {
				"url": "/images/content/ru/clients/EuropeTech.svg"
			}
		},
		{
			"id": 140,
			"title": {
				"ru": "Интер РАО ЕЭС",
				"en": "Интер РАО КЭС"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"power-engineering"
			],
			"images": {
				"url": "/images/content/ru/clients/InterRaoues.svg"
			}
		},
		{
			"id": 141,
			"title": {
				"ru": "Кубаньэнерго",
				"en": "Кубаньэнерго"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"power-engineering"
			],
			"images": {
				"url": "/images/content/ru/clients/Kubanenergo.svg"
			}
		},
		{
			"id": 142,
			"title": {
				"ru": "Мвидео",
				"en": "Мвидео"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"consumer-sector"
			],
			"images": {
				"url": "/images/content/ru/clients/MvideoEldorado.svg"
			}
		},
		{
			"id": 143,
			"title": {
				"ru": "Россети Сибирь",
				"en": "Россети Сибирь"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"power-engineering"
			],
			"images": {
				"url": "/images/content/ru/clients/mrsk-sib.svg"
			}
		},
		{
			"id": 144,
			"title": {
				"ru": "Озон",
				"en": "Озон"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"consumer-sector",
				"it"
			],
			"images": {
				"url": "/images/content/ru/clients/ozon.svg"
			}
		},
		{
			"id": 145,
			"title": {
				"ru": "Совкомфлот",
				"en": "Совкомфлот"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"logistics"
			],
			"images": {
				"url": "/images/content/ru/clients/SCF.svg"
			}
		},
		{
			"id": 146,
			"title": {
				"ru": "FreightVillage",
				"en": "FreightVillage"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"logistics"
			],
			"images": {
				"url": "/images/content/ru/clients/FreightVillage.svg"
			}
		},
		{
			"id": 147,
			"title": {
				"ru": "ЦОДД",
				"en": "ЦОДД"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"non-profit-organizations",
				"logistics"
			],
			"images": {
				"url": "/images/content/ru/clients/codd_wb.svg"
			}
		},
		{
			"id": 148,
			"title": {
				"ru": "КазМунайГаз",
				"en": "КазМунайГаз"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"oil-and-gas"
			],
			"images": {
				"url": "/images/content/ru/clients/kmg_wb.svg"
			}
		},
		{
			"id": 149,
			"title": {
				"ru": "ОТЛК",
				"en": "ОТЛК"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"logistics"
			],
			"images": {
				"url": "/images/content/ru/clients/utlc_wb.svg"
			}
		},
		{
			"id": 150,
			"title": {
				"ru": "Почта России",
				"en": "Почта России"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"logistics",
				"consumer-sector"
			],
			"images": {
				"url": "/images/content/ru/clients/pochta_rf.svg"
			}
		},
		{
			"id": 151,
			"title": {
				"ru": "ФГК",
				"en": "ФГК"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"logistics"
			],
			"images": {
				"url": "/images/content/ru/clients/fgk_wb.svg"
			}
		},
		{
			"id": 152,
			"title": {
				"ru": "НЛМК",
				"en": "НЛМК"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"metallurgy"
			],
			"images": {
				"url": "/images/content/ru/clients/nlmk_wb.svg"
			}
		},
		{
			"id": 153,
			"title": {
				"ru": "ЧТПЗ",
				"en": "ЧТПЗ"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"metallurgy"
			],
			"images": {
				"url": "/images/content/ru/clients/chtpz.svg"
			}
		},
		{
			"id": 154,
			"title": {
				"ru": "Казхром",
				"en": "Казхром"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"metallurgy"
			],
			"images": {
				"url": "/images/content/ru/clients/kazchrome_wb.svg"
			}
		},
		{
			"id": 155,
			"title": {
				"ru": "QIWI",
				"en": "QIWI"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"financial-sector"
			],
			"images": {
				"url": "/images/content/ru/clients/qiwi_wb.svg"
			}
		},
		{
			"id": 156,
			"title": {
				"ru": "ПСБ",
				"en": "ПСБ"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"financial-sector"
			],
			"images": {
				"url": "/images/content/ru/clients/psb_wb.svg"
			}
		},
		{
			"id": 157,
			"title": {
				"ru": "Казахтелеком",
				"en": "Казахтелеком"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"consumer-sector",
				"it"
			],
			"images": {
				"url": "/images/content/ru/clients/kaztelecom_wb.svg"
			}
		},
		{
			"id": 158,
			"title": {
				"ru": "МТС",
				"en": "МТС"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"consumer-sector",
				"it"
			],
			"images": {
				"url": "/images/content/ru/clients/mts_wb.svg"
			}
		},
		{
			"id": 159,
			"title": {
				"ru": "Мегафон",
				"en": "Мегафон"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"consumer-sector",
				"it"
			],
			"images": {
				"url": "/images/content/ru/clients/megafon_wb.svg"
			}
		},
		{
			"id": 160,
			"title": {
				"ru": "Шубарколь",
				"en": "Шубарколь"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"metallurgy"
			],
			"images": {
				"url": "/images/content/ru/clients/shubarkol-wb.svg"
			}
		},
		{
			"id": 161,
			"title": {
				"ru": "Система",
				"en": "Система"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"financial-sector"
			],
			"images": {
				"url": "/images/content/ru/clients/afk.svg"
			}
		},
		{
			"id": 162,
			"title": {
				"ru": "Газпром медиа холдинг",
				"en": "Газпром медиа холдинг"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"consumer-sector"
			],
			"images": {
				"url": "/images/content/ru/clients/gpn-media.svg"
			}
		},
		{
			"id": 163,
			"title": {
				"ru": "Лента",
				"en": "Лента"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"consumer-sector"
			],
			"images": {
				"url": "/images/content/ru/clients/lenta.svg"
			}
		},
		{
			"id": 164,
			"title": {
				"ru": "ЛСР",
				"en": "ЛСР"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"building"
			],
			"images": {
				"url": "/images/content/ru/clients/lsr.svg"
			}
		},
		{
			"id": 165,
			"title": {
				"ru": "Окей",
				"en": "Окей"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"consumer-sector"
			],
			"images": {
				"url": "/images/content/ru/clients/okay.svg"
			}
		},
		{
			"id": 166,
			"title": {
				"ru": "РЖД Логистика",
				"en": "РЖД Логистика"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"logistics"
			],
			"images": {
				"url": "/images/content/ru/clients/rzd-logistic.svg"
			}
		},
		{
			"id": 167,
			"title": {
				"ru": "Рольф",
				"en": "Рольф"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"consumer-sector"
			],
			"images": {
				"url": "/images/content/ru/clients/rolf.svg"
			}
		},
		{
			"id": 168,
			"title": {
				"ru": "Россети Северный Кавказ",
				"en": "Россети Северный Кавказ"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"power-engineering"
			],
			"images": {
				"url": "/images/content/ru/clients/rosseti-sk.svg"
			}
		},
		{
			"id": 169,
			"title": {
				"ru": "Самолет",
				"en": "Самолет"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"building"
			],
			"images": {
				"url": "/images/content/ru/clients/samolet.svg"
			}
		},
		{
			"id": 170,
			"title": {
				"ru": "Тинькофф",
				"en": "Тинькофф"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"financial-sector"
			],
			"images": {
				"url": "/images/content/ru/clients/tinkoff.svg"
			}
		},
		{
			"id": 171,
			"title": {
				"ru": "Яндекс",
				"en": "Яндекс"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"it"
			],
			"images": {
				"url": "/images/content/ru/clients/yandex.svg"
			}
		},
		{
			"id": 172,
			"title": {
				"ru": "Московский Кредитный Банк",
				"en": "Московский Кредитный Банк"
			},
			"subTitle": {
				"ru": "Годовой отчет",
				"en": "Annual report"
			},
			"groups": [
				"financial-sector"
			],
			"images": {
				"url": "/images/content/ru/clients/mkb.svg"
			}
		}
	]
};