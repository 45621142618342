//конфиг шаблона компонента
const config = require('./config.yaml');
//базовый класс компонента
const CTracking = require('../../index.js');

/**
 * Класс CTracking_base, наследник CTracking
 * Реализует логику варианта base компонента tracking
 */
class CTracking_base extends CTracking {
	constructor() {
		super();
	}

	/**
	 * Инициализация функционала
	 */
	init() {
		//сохранить конфиг шаблона компонента
		this.config = config;
		//сохранить язык сайта
		this.lang = templateVars.lang;
		//сохранить код сайта
		this.site = templateVars.site;

		if (!this.config.systems[this.site]) {
			return false;
		}

		//актуальный по языковым версиям и сайтам конфиг настроек метрик
		this.currentSystemsSiteLangConfig = {};
		for (let name in this.config.systems[this.site][this.lang]) {
			const system = this.config.systems[this.site][this.lang][name];

			if (system.id != 0) {
				this.currentSystemsSiteLangConfig[name] = system;
			}
		}

		if (!Object.keys(this.currentSystemsSiteLangConfig).length) {
			return false;
		}

		//конфиги для достижения целей в системе
		this.goalsConfigs = {};

		//карта имен свойств
		this.propertiesNames = {
			//имя атрибута, в котором хранится тип отслеживаемого события
			type: 'data-tracker-event-type',
			//имя атрибута, в котором хранится ID события для связи в конфиге
			id: 'data-tracker-event-id',
		};

		//получение информации о юзере
		this.user = this.getUserInfo();

		//дефолтные параметры для передачи в достижение цели
		this.defaultUserParams = {
			fields: {
				// userType: 'regular',
				// lang: this.lang,
				// ip: this.user.ip
			}
		};

		this.defaultReachGoalParams = {
			fields: {
				userType: 'regular',
				lang: this.lang,
				ip: this.user.ip,
			},
			goalID: false,
		};

		//если пользователь определен, то добавляем в конфиг информацию о utm-метке пользователя
		if (this.user['key']) {
			this.defaultReachGoalParams.fields['utmUserCode'] = this.user['key'];
			this.defaultReachGoalParams.fields['userType'] = 'utm';
		}

		//массив для сбора промисов подгрузки конфигов с целями
		let goalsConfigsPromises = [];
		//обходим конфиги метрик
		for (let systemName in this.currentSystemsSiteLangConfig) {
			//получаем конфиг метрики
			const system = this.currentSystemsSiteLangConfig[systemName];

			//если в конфиге метрики выставлен параметра работы с целями
			if (system.trackGoals) {
				//подгружаем конфиги для целей асинхронно и записываем в класс для дальнейшей работы
				goalsConfigsPromises.push(new Promise((resolve, reject) => {
					//ассинхронная подгрузка конфига с коллбеком
					require([`./goalsConfigs/${this.site}/${this.lang}/${systemName}.yaml`], (config) => {
						//если конфиг загрузился не пустой - записываем его
						if (Object.keys(config).length) {
							this.goalsConfigs[systemName] = config;
						} else {
							this.sendError(`в файле ./goalsConfigs/${this.site}/${this.lang}/${systemName}.yaml пустой конфиг`);
						}

						resolve();
					});
				}));
			}
		}

		//ждем, пока подгрузятся все дополнительные конфииги для работы с целями
		Promise.all(goalsConfigsPromises)
			.then(() => {
				$(window).on('load', () => {
					this.setYandexUserParams(this.defaultUserParams.fields);

					//провешиваем обработчики на отслеживаемые элементы
					this.initListener();

					//отсылаем данные о пользователей в трекинг-системы
					this.onEvent('visit');
				});
			})
			.catch((error) => {
				this.sendError(error);
			});
	}
	/**
	 * Метод для отлова взаимодействия с отслеживаемыми элементами
	 * Если переданы элементы в параметре $passedTargets, то обработчики будут повешаны на них
	 * Это может быть полезно, когда, например, необходимо провесить обработку на элементы, создаваемые асинхронно
	 * @param $passedTargets
	 */
	initListener($passedTargets = false) {
		let $targets = false;

		// переданы ли в метод объекты
		if ($passedTargets.length) {
			// записываем как цели их
			$targets = $passedTargets;
		} else {
			//получаем все элементы со страницы с атрибутом отслеживания
			$targets = $(`[${this.propertiesNames.type}]`);

			//элементы получены?
			if ($targets.length) {
				//обходим все полученные элеменыт
				$.each($targets, (i, item) => {
					//преобразуем текущий элемент к объекту jQuery
					const $item = $(item);
					//получаем тип действия, по которому должен отслеживаться элемент
					const eventType = $item.attr(this.propertiesNames.type);

					//провешиваем обработчки в зависимости от действия
					switch (eventType) {
						case 'click': // обработка клика по элементу
							$item.on('click auxclick', (e) => {
								this.onEvent('click', $item);
							});
							break;
						case 'checkIn': // обработка выставления галочки на элемент
							$item.on('change', (e) => {
								this.onEvent('checkIn', $item);
							});
							break;
						case 'checkOut': //обработка снятия галочки на элемент
							$item.on('change', (e) => {
								this.onEvent('checkOut', $item);
							});
							break;
						default:
							this.sendError(`неизвестный тип действия \"${actionType}\" для элемента ${item}]`);
					}
				});
			}
		}
	}

	/**
	 * Метод проксирования. В зависимости от переданного события, вызывает тот или иной обработчик
	 * @param eventType
	 * @param $item
	 * @returns {boolean}
	 */
	onEvent(eventType = false, $item = false) {
		if (!eventType) { //если не передан тип действия, но выходим с ошибкой
			this.sendError(`не указано действие для обработки`);
			return false;
		}

		//провешиваем обработчки в зависимости от действия
		switch (eventType) {
			case 'click': // обработка клика по элементу
				if (!$item) {
					this.sendError(`не передан элемент, над которым происходит обработка действия click`);
				} else {
					this.processClickEvent($item);
				}

				break;
			case 'checkIn': // обработка события выставления галочки
				this.processCheckInEvent($item);

				break;
			case 'checkOut': // обработка события снятия галочки
				this.processCheckOutEvent($item);

				break;
			case 'visit': // обработка события посещения пользователя
				this.processVisitEvent();

				break;
			default:
				this.sendError(`неизвестное событие ${eventType} для элемента ${item}]`);
		}
	}

	/**
	 * Подготовка данных для отправки сведений о клике на объект
	 * Настройки целей о посещении хранятся в основном конфиге
	 *
	 * @param $item
	 * @returns {boolean}
	 */
	processClickEvent($item = false) {
		if ($item && !$item.length) {
			this.sendError(`не передан элемент в обработчик processClickEvent`);
			return false;
		}

		//получаем цель, которая указана у элемента
		const goalID = $item.attr(this.propertiesNames.id);
		//получаем цель из конфига кастомных целей
		const goalInConfig = this.getYandexGoalByID(goalID);

		if (!goalInConfig) {
			this.sendError(`не обнаружен конфиг для Yandex-цели \"${goalID}\"`);
			return false;
		}

		//обходим все метрики из конфига
		for (let systemName in this.currentSystemsSiteLangConfig) {
			//получаем текущую метрику с шага
			const system = this.currentSystemsSiteLangConfig[systemName];
			//дублируем параметры в именнованную область в параметрах
			let params = this.getFormatYandexParams(AR.tools.cloneObject(this.defaultReachGoalParams.fields), goalInConfig);

			switch (systemName) {
				case 'yandex': //если метрика - Яндекс
					//отправляем достижение цели
					this.reachYandexGoalByID(goalID, params, () => {
						this.sendMessage(`достижение цели Yandex \"${goalID}\" с конфигом ${JSON.stringify(params)}`);
					});
					break;
				case 'google':
				case 'mail':
				case 'leadforensics':
					break;
				default:
					this.sendError(`не известная для обработки система ${systemName}`);
			}
		}
	}

	/**
	 * Подготовка данных для отправки сведений о выставление галочки на чекбокс
	 * Настройки целей о посещении хранятся в основном конфиге
	 * @param $item
	 * @returns {boolean}
	 */
	processCheckInEvent($item = false) {
		if ($item && !$item.length) {
			this.sendError(`не передан элемент в обработчик processCheckInEvent`);
			return false;
		}

		// проверяем, что галочка была выставлена
		if ($item.attr('type') == 'checkbox' && !$item.prop('checked')) {
			return false;
		}

		//получаем цель, которая указана у элемента
		const goalID = $item.attr(this.propertiesNames.id);
		const goalInConfig = this.getYandexGoalByID(goalID);

		if (!goalInConfig) {
			this.sendError(`не обнаружен конфиг для Yandex-цели ${goalID}`);
			return false;
		}

		//обходим все метрики из конфига
		for (let systemName in this.currentSystemsSiteLangConfig) {
			//получаем текущую метрику с шага
			const system = this.currentSystemsSiteLangConfig[systemName];
			//клонируем конфиг из дефолтного
			let params = this.getFormatYandexParams(AR.tools.cloneObject(this.defaultReachGoalParams.fields), goalInConfig);

			switch (systemName) {
				case 'yandex': //если метрика - Яндекс
					//отправляем достижение цели
					this.reachYandexGoalByID(goalID, params, () => {
						this.sendMessage(`достижение цели Yandex \"${goalID}\" с конфигом ${JSON.stringify(params)}`);
					});
					break;
				case 'google':
				case 'mail':
				case 'leadforensics':
					break;
				default:
					this.sendError(`не известная для обработки система ${systemName}`);
			}
		}
	}

	/**
	 * Подготовка данных для отправки сведений о снятия галочки на чекбокс
	 * Настройки целей о посещении хранятся в основном конфиге
	 * @param $item
	 * @returns {boolean}
	 */
	processCheckOutEvent($item = false) {
		if ($item && !$item.length) {
			this.sendError(`не передан элемент в обработчик processCheckOutEvent`);
			return false;
		}

		// проверяем, что галочка была снята
		if ($item.attr('type') == 'checkbox' && $item.prop('checked')) {
			return false;
		}

		//получаем цель, которая указана у элемента
		const goalID = $item.attr(this.propertiesNames.id);
		const goalInConfig = this.getYandexGoalByID(goalID);

		if (!goalInConfig) {
			this.sendError(`не обнаружен конфиг для Yandex-цели \"${goalID}\"`);
			return false;
		}

		//обходим все метрики из конфига
		for (let systemName in this.currentSystemsSiteLangConfig) {
			//получаем текущую метрику с шага
			const system = this.currentSystemsSiteLangConfig[systemName];
			//клонируем конфиг из дефолтного
			let params = this.getFormatYandexParams(AR.tools.cloneObject(this.defaultReachGoalParams.fields), goalInConfig);

			switch (systemName) {
				case 'yandex': //если метрика - Яндекс
					//отправляем достижение цели
					this.reachYandexGoalByID(goalID, params, () => {
						this.sendMessage(`достижение цели Yandex \"${goalID}\" с конфигом ${JSON.stringify(params)}`);
					});
					break;
				case 'google':
				case 'mail':
				case 'leadforensics':
					break;
				default:
					this.sendError(`не известная для обработки система ${systemName}`);
			}
		}
	}

	/**
	 * Подготовка данных для отправки сведений о фиксации пользователя
	 * Настройки целей о посещении хранятся в основном конфиге
	 */
	processVisitEvent() {
		//обходим все метрики из конфига
		for (let systemName in this.currentSystemsSiteLangConfig) {
			//получаем текущую метрику с шага
			const system = this.currentSystemsSiteLangConfig[systemName];
			const goalID = system.userVisitedGoalID;

			switch (systemName) {
				case 'yandex': //если метрика - Яндекс
					//если в основном конфиге задана цель о визите для достижения
					if (system.userVisitedGoalID) {
						//клонируем конфиг из дефолтного
						let params = this.getFormatYandexParams(AR.tools.cloneObject(this.defaultReachGoalParams.fields));

						//отправляем достижение цели
						this.reachYandexGoalByID(goalID, params, () => {
							this.sendMessage(`достижение цели Yandex \"${goalID}\" с конфигом ${JSON.stringify(params)}`);
						});
					} else {
						// this.sendError('в конфиге Yandex не найдена цель для для фиксации посещения пользователя')
					}


					break;
				case 'google':
				case 'mail':
				case 'leadforensics':
					break;
				default:
					this.sendError(`не известная для обработки система ${systemName}`);
			}
		}
	}

	/**
	 * Получить инфу о залогиненном пользователе
	 * @returns {boolean}
	 */
	getUserInfo() {
		//если пользователь затрекан в системе
		return (templateVars.user) ? templateVars.user : false;
	}

	/**
	 * Получить цель Яндекса из конфига по ID
	 * @param goalID - цель
	 * @returns {boolean}
	 */
	getYandexGoalByID(goalID = false) {
		//если передами параметр для поиска и при этом загружен конфг с целями
		if (goalID && this.goalsConfigs['yandex']) {
			const goal = this.goalsConfigs['yandex'].filter((goal) => {
				return goal.conditions.url == goalID;
			});

			return (goal.length) ? goal[0] : false;
		} else {
			this.sendError(`ошибка получения цели из конфига Yandex по ID \"${goalID}\"`);
			return false;
		}
	}

	/**
	 * Достижение цели Яндекса
	 * @param goalID - цель
	 * @param fields - параметры цели
	 */
	reachYandexGoalByID(goalID, fields = {}, callback) {
		//получаем код метрики
		const yaCounter = this.getYandexCounterObject();

		//если код существует
		if (yaCounter) {
			//если в конфиге передана цель для достижения
			if (goalID) {
				//отправляем достижение цели с параметрами
				yaCounter.reachGoal(goalID, fields, () => {
					if (typeof callback == 'function') {
						callback();
					}
				});
			} else {
				this.sendError(`не передан ID цели Yandex для достижения`);
			}
		} else {
			this.sendError(`ошибка получения объекта счетчика Yandex`);
		}
	}

	getFormatYandexParams(params = {}, goalConfig = {}) {
		const cloneParams = AR.tools.cloneObject(params);
		const clientID = this.getYandexClientID();

		if (goalConfig) {
			cloneParams['filter'] = {
				sort: {
					[clientID]: $.extend({}, params, {
						targetsGoalName: goalConfig.name,
						url: document.URL
					})
				}
			};
		} else {
			cloneParams['filter'] = {
				sort: params
			};
		}

		return cloneParams;
	}

	/**
	 * Получить объект yaCounter
	 */
	getYandexCounterObject() {
		return window[`yaCounter${this.currentSystemsSiteLangConfig['yandex']['id']}`];
	}

	getYandexClientID() {
		//получаем код метрики
		const yaCounter = this.getYandexCounterObject();

		//если код существует
		if (yaCounter) {
			return yaCounter.getClientID();
		} else {
			this.sendError(`ошибка получения объекта счетчика Yandex`);
		}
	}

	setYandexUserParams(params) {
		//получаем код метрики
		const yaCounter = this.getYandexCounterObject();

		//если код существует
		if (yaCounter) {
			this.sendMessage(`выставление параметров пользователя Yandex с конфигом ${JSON.stringify(params)}`);
			return yaCounter.userParams(params);
		} else {
			this.sendError(`ошибка получения объекта счетчика Yandex`);
		}
	}
}

AR.waitComponents([], () => {
	const cTracking_base = new CTracking_base();

	// Вызов метода со всеми событиями
	cTracking_base.init();
	// Добавление в глобальный объект AR.components
	AR.pushComponent(cTracking_base, 'cTracking_base');
});
