module.exports = typeof templateVars !== 'undefined' && typeof testComponentName !== 'undefined' && testComponentName === 'projects/projects' && templateVars.config ?
				templateVars.config :
				{
	"sort": [
		"years",
		"alphabet",
		"priority"
	],
	"casesPageName": "cases",
	"saveScrollPos": true,
	"scrollDuration": 800,
	"gridParams": {
		"class": "col-3 col-md-12 col-sm-12"
	},
	"breakpoints": {
		"lg": 1270,
		"md": 1054,
		"sm": 798,
		"xs": 510
	},
	"groups": [
		{
			"id": "annual-reports",
			"filter": [
				"annual-reports"
			],
			"title": {
				"ru": "Годовые отчеты",
				"en": "Annual reports"
			}
		},
		{
			"id": "esef-ixbrl",
			"filter": [
				"esef-ixbrl"
			],
			"title": {
				"ru": "ESEF-отчеты (IXBRL)",
				"en": "ESEF compliant reports (IXBRL)"
			}
		},
		{
			"id": "online-reports",
			"filter": [
				"online-reports"
			],
			"title": {
				"ru": "Онлайн-отчеты",
				"en": "Online reports"
			}
		},
		{
			"id": "esg-csr",
			"filter": [
				"esg-csr"
			],
			"title": {
				"ru": "ESG, ОУР и&nbsp;КСО",
				"en": "Sustainability and ESG reports"
			}
		}
	],
	"items": [
		{
			"id": 44,
			"title": {
				"ru": "КазМунайГаз",
				"en": "KazMunayGas"
			},
			"subTitle": {
				"ru": "Annual report-2020<br>Online annual report-2020",
				"en": "Annual report-2020<br>Online annual report-2020"
			},
			"groups": [
				"online-reports"
			],
			"images": {
				"lg": "/images/content/en/cases/kmg_preview--2020.png",
				"md": "/images/content/en/cases/kmg_preview--2020.png",
				"sm": "/images/content/en/cases/kmg_preview--2020.png",
				"xl": "/images/content/en/cases/kmg_preview--2020.png"
			},
			"years": [
				2020
			],
			"link": "cases/kmg-2020",
			"priority": 11
		},
		{
			"id": 53,
			"title": {
				"en": "Rusagro",
				"ru": "Русагро"
			},
			"subTitle": {
				"ru": "Annual report-2020<br>Online annual report-2020",
				"en": "Annual report-2020<br>Online annual report-2020"
			},
			"groups": [
				"annual-reports"
			],
			"images": {
				"lg": "/images/content/en/cases/rusagro_preview--2020.png",
				"md": "/images/content/en/cases/rusagro_preview--2020.png",
				"sm": "/images/content/en/cases/rusagro_preview--2020.png",
				"xl": "/images/content/en/cases/rusagro_preview--2020.png"
			},
			"years": [
				2020
			],
			"link": "cases/rusagro-2020",
			"priority": 13
		},
		{
			"id": 65,
			"title": {
				"en": "Fesco",
				"ru": "Fesco"
			},
			"subTitle": {
				"ru": "Annual report-2020<br>Online annual report-2020",
				"en": "Annual report-2020<br>Online annual report-2020"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/en/cases/fesco_preview--2020.png",
				"md": "/images/content/en/cases/fesco_preview--2020.png",
				"sm": "/images/content/en/cases/fesco_preview--2020.png",
				"xl": "/images/content/en/cases/fesco_preview--2020.png"
			},
			"years": [
				2020
			],
			"link": "cases/fesco-2020"
		},
		{
			"id": 67,
			"title": {
				"en": "Rusagro",
				"ru": "Rusagro"
			},
			"subTitle": {
				"ru": "Annual report-2021<br>Online annual report-2021<br>Sustainability report-2021<br>Online sustainability report-2021<br>ESEF-Compliance",
				"en": "Annual report-2021<br>Online annual report-2021<br>Sustainability report-2021<br>Online sustainability report-2021<br>ESEF-Compliance"
			},
			"groups": [
				"annual-reports",
				"online-reports",
				"esg-csr",
				"esef-ixbrl"
			],
			"images": {
				"lg": "/images/content/en/projects-pages/rusagro_2021-prev.png",
				"md": "/images/content/en/projects-pages/rusagro_2021-prev.png",
				"sm": "/images/content/en/projects-pages/rusagro_2021-prev.png",
				"xl": "/images/content/en/projects-pages/rusagro_2021-prev.png"
			},
			"years": [
				2021
			],
			"link": "cases/rusagro-2021"
		},
		{
			"id": 68,
			"title": {
				"en": "Gulf Navigation",
				"ru": "Gulf Navigation"
			},
			"subTitle": {
				"ru": "Integrated report-2021<br>Online Integrated report-2021",
				"en": "Integrated report-2021<br>Online Integrated report-2021"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/en/projects-pages/gulfnav_2021-prev.png",
				"md": "/images/content/en/projects-pages/gulfnav_2021-prev.png",
				"sm": "/images/content/en/projects-pages/gulfnav_2021-prev.png",
				"xl": "/images/content/en/projects-pages/gulfnav_2021-prev.png"
			},
			"years": [
				2021
			],
			"link": "cases/gulf-navigation-2021"
		},
		{
			"id": 69,
			"title": {
				"en": "Autonom",
				"ru": "Autonom"
			},
			"subTitle": {
				"ru": "Sustainability report-2021<br>Online sustainability report-2021",
				"en": "Sustainability report-2021<br>Online sustainability report-2021"
			},
			"groups": [
				"online-reports",
				"esg-csr"
			],
			"images": {
				"lg": "/images/content/en/projects-pages/autonom_2021-prev.png",
				"md": "/images/content/en/projects-pages/autonom_2021-prev.png",
				"sm": "/images/content/en/projects-pages/autonom_2021-prev.png",
				"xl": "/images/content/en/projects-pages/autonom_2021-prev.png"
			},
			"years": [
				2021
			],
			"link": "cases/autonom-2021"
		},
		{
			"id": 70,
			"title": {
				"en": "KASE",
				"ru": "KASE"
			},
			"subTitle": {
				"ru": "Annual report-2020<br>Online annual report-2020",
				"en": "Annual report-2020<br>Online annual report-2020"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/en/projects-pages/kase_2020-prev.png",
				"md": "/images/content/en/projects-pages/kase_2020-prev.png",
				"sm": "/images/content/en/projects-pages/kase_2020-prev.png",
				"xl": "/images/content/en/projects-pages/kase_2020-prev.png"
			},
			"years": [
				2020
			],
			"link": "cases/kase-2021"
		},
		{
			"id": 71,
			"title": {
				"en": "Fesco",
				"ru": "Fesco"
			},
			"subTitle": {
				"ru": "Annual report-2021<br>Online annual report-2021",
				"en": "Annual report-2021<br>Online annual report-2021"
			},
			"groups": [
				"annual-reports",
				"online-reports"
			],
			"images": {
				"lg": "/images/content/en/projects-pages/fesco-mockup--2021-prev.png",
				"md": "/images/content/en/projects-pages/fesco-mockup--2021-prev.png",
				"sm": "/images/content/en/projects-pages/fesco-mockup--2021-prev.png",
				"xl": "/images/content/en/projects-pages/fesco-mockup--2021-prev.png"
			},
			"years": [
				2021
			],
			"link": "cases/fesco-2021"
		}
	]
};