//конфиг шаблона компонента
const config = require('./config.yaml');
//базовый класс компонента
const CProject = require('../../index.js');
const cookie = require('cookie_js');
const {throttle} = require('throttle-debounce');

/**
 * Класс CProjects_projects, наследник CProject
 * Реализует логику шаблона projects компонента CProject
 */
class CProjects_projects extends CProject {
	constructor() {
		super();

		//конфиг шаблона компонента
		this.config = config;
		this.$projectsWrapper = null;
		this.posCookieName = 'projects-scroll-pos';
	}

	/**
	 * Инициализация компонента
	 */
	init() {
		//TODO заложить вероятность присутствия нескольких одинаковых компонентов на странице
		this.$projectsWrapper = $('#projects');
		this.mainProjects = document.querySelector('.b-projects._main');
		const posCookie = this.getCookie();

		if (this.$projectsWrapper.length) {
			this.$shuffleContainer = this.$projectsWrapper.find('[data-shuffle-container]');
			// this.$filterTriggersLinks = this.$projectsWrapper.find('[data-filter-trigger]');
			this.$filterTriggers = this.$projectsWrapper.find('.js-trigger');

			this.getShuffleModule()
				.then((Shuffle) => {
					this.shuffleInstance = new Shuffle(this.$shuffleContainer[0], {
						itemSelector: '.js-item',
					});

					this.filterLogic();
				});

			if (this.config.saveScrollPos) {
				if (posCookie && posCookie !== '0' && posCookie !== window.pageYOffset.toString()) {
					this.scrollToSavePos(posCookie);
				} else {
					this.saveScrollPos();
				}
			}
		} else if (this.config.saveScrollPos && posCookie) {
			const regExp = new RegExp(`^${this.config.casesPageName}(\/|$)`, 'i');
			const isCasePage = templateVars.siteUrl.pathTail.match(regExp);

			if (!isCasePage) {
				this.removeCookie();
			}
		} else if (this.mainProjects) {
			this.initMainMosaic(this.mainProjects);
		}

	}

	filterLogic() {
		this.$filterTriggers.on('click', (e) => {
			let $trigger = $(e.currentTarget);
			let filterMask = JSON.parse($trigger.attr('data-filter-group'));

			this.$filterTriggers.removeClass('is-active');

			this.shuffleInstance.filter(filterMask);
			this.shuffleInstance.update();

			$trigger.addClass('is-active');

			return false;
		});
	}

	saveScrollPos() {
		this.setCookie(window.pageYOffset);
		window.addEventListener('scroll', throttle(200, () => this.setCookie(window.pageYOffset)));
	}

	setCookie(value) {
		cookie.set(this.posCookieName, value, {path: '/', expires: 365});
	}

	removeCookie() {
		cookie.removeSpecific(this.posCookieName, {path: '/'});
	}

	getCookie() {
		return cookie.get(this.posCookieName);
	}

	scrollToSavePos(pos) {
		const body = document.querySelector('body');

		$(body).velocity('scroll', {
			duration: this.config.scrollDuration,
			offset: pos,
			easing: 'ease-in-out',
			complete: () => {
				this.saveScrollPos();
			}
		});
	}

	initMainMosaic(projects) {
		this.lastChanged = [];

		const plates = [...projects.querySelectorAll('.b-project-list-item')];
		let initial = plates.filter((item) => item.offsetParent);
		let rest = plates.filter((item) => !item.offsetParent);

		let changePlates = (countVal) => {
			let activeItems = plates.filter((item) => item.classList.contains('is-active') && !item.classList.contains('in-processing'));
			let count = countVal || activeItems.length;

			this.getTargetIdx(count, activeItems).forEach(idx => {
				const target = activeItems[idx];
				const replaceItem = rest.shift();
				// const timeout = Math.floor(Math.random() * 2200) + 1200;
				const timeout = 1000;
				const cb = () => changePlates(1);

				rest.push(target);

				target.classList.add('in-processing');
				replaceItem.classList.add('in-processing');

				setTimeout(() => {
					this.setHide(target);
					this.setShow(replaceItem, cb);
					this.swapNodes(target, replaceItem);
				}, timeout);
			});
		};

		if (rest.length) {
			this.shuffleArray(rest);
			initial.forEach((item) => item.classList.add('is-active'));

			if ($('html').hasClass('browser-ie')) {
				this.ieFixes();
			}

			changePlates();
		}
	}

	setShow(item, cb) {
		item.style.display = 'block';
		item.style.position = '';
		item.style.top = '';
		item.style.left = '';
		item.style.opacity = '0';

		$(item).velocity('fadeIn', {
			duration: 800,
			easing: 'ease',
			begin: () => {
				item.classList.add('is-active');

				if (this.lastChanged.length < 2) {
					this.lastChanged.push(item.dataset.id);
				} else {
					this.lastChanged.shift();
					this.lastChanged.push(item.dataset.id);
				}
			},
			complete: () => {
				item.classList.remove('in-processing');
				cb();
			},
		});
	}

	setHide(item) {
		item.style.top = `${item.offsetTop}px`;
		item.style.left = `${item.offsetLeft}px`;
		item.style.display = 'block';
		item.style.position = 'absolute';

		$(item).velocity('fadeOut', {
			duration: 800,
			easing: 'ease',
			begin: () => item.classList.remove('is-active'),
			complete: () => {
				item.classList.remove('in-processing');
				item.style.position = '';
				item.style.top = '';
				item.style.left = '';
			},
		});
	}

	getTargetIdx(count, items) {
		let numbers = [];
		// let maxEls = Math.floor(count / 2) || 1;
		let maxEls = 1;
		let getRandomNumber = () => {
			const num = Math.floor(Math.random() * items.length) + 0;

			// if (numbers.includes(num) && !items[num].classList.contains('in-processing')) {
			if (numbers.includes(num) || this.lastChanged.includes(String(items[num].dataset.id))) {
				return getRandomNumber();
			}

			return num;
		};

		for (let i = 0; i < maxEls; i++) {
			numbers.push(getRandomNumber());
		}

		return numbers;
	}

	swapNodes(node1, node2) {
		const afterNode2 = node2.nextElementSibling;
		const parent = node2.parentNode;
		node1.replaceWith(node2);
		parent.insertBefore(node1, afterNode2);
	}

	shuffleArray(array) {
		for (let i = array.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[array[i], array[j]] = [array[j], array[i]];
		}
	}

	ieFixes() {
		Element.prototype.replaceWith = function () {
			var parent = this.parentNode, i = arguments.length, currentNode;
			if (!parent) {
				return;
			}
			if (!i) {
				parent.removeChild(this);
			}
			while (i--) {
				currentNode = arguments[i];
				if (typeof currentNode !== 'object') {
					currentNode = this.ownerDocument.createTextNode(currentNode);
				} else if (currentNode.parentNode) {
					currentNode.parentNode.removeChild(currentNode);
				}

				if (!i) {
					parent.replaceChild(currentNode, this);
				} else {
					parent.insertBefore(currentNode, this.nextSibling);
				}
			}
		};
	}
}

const cProject_projects = new CProjects_projects();

cProject_projects.init();

AR.pushComponent(cProject_projects, 'cProject_projects');
