const CUpButton = require('../../index.js');
const config = require('./config.yaml');

class CUpButton_side extends CUpButton {
	// В параметрах передается config.yaml данного варианта, и название варианта для создания глобального события в общем классе
	constructor(config, componentVariantName) {
		super(config, componentVariantName);
		// Значение данной переменной подставляется в название создаваемых глобальных событий, в общем файле js
		this.componentVariantName = componentVariantName;
	}
	// Инициализация всех существующих событий (событие клика и скролла)
	init() {
		// Получаем главный блок
		this.$upButton = $('.js-up-button-side');

		if (this.$upButton && this.$upButton.length) {
			// Значение, которое должен прокрутить пользователь, чтобы появилась кнопка - "Вверх"
			this.scrollTopValue = $('.l-page__content').offset().top;
			// Получаем тег "body"
			this.$body = $('html');
			// Получаем глобальный объект - window
			this.$window = $(window);
			// Провешивание обработчика, где инициализируется событие прокрутки вверх
			this.$upButton.on('click', $.proxy(this, 'onScrollToUp'));
			// Провешивание обработчика, где инициализируется событие появление/исчезновение кнопки - "Вверх"
			$(window).on('scroll', $.proxy(this, 'onToggleUpButtonVisible'));
		}
	}
}

const cUpButton_side = new CUpButton_side(config, 'UpButtonSide');
// Вызов метода, инициализирующего все существующие события
cUpButton_side.init();
// Добавление в глобальный объект AR.components
AR.pushComponent(cUpButton_side, 'cUpButton_side');
