//конфиг шаблона компонента
const config = require('./config.yaml');
//базовый класс компонента
const CSlider = require('../../index.js');

const {throttle} = require('throttle-debounce');
// const isElementInView = require('component/core/.variant/base/additionals/isElementInView.js');

/**
 * Класс CSlider_main, наследник CSlider
 * Реализует логику шаблона base компонента slider
 */
class CSlider_main extends CSlider {
	constructor() {
		super();
		this.config = config;
		this.sliderState = {
			mouseHelper: true,
			// window.orientation == 0
			mobile: window.innerWidth > 798 ? false : true
		};
	}

	async init() {
		this.$sliderWrapper = $(`.js-slider-wrapper--${config.name}`);

		if (this.$sliderWrapper.length) {
			this.$bgMain = this.$sliderWrapper.find('.js-bg-main');
			this.body = document.querySelector('body');
			this.preloader = document.querySelector('.js-preloader');
			this.mouse = document.querySelector('.js-mouse');

			this.subSliderWrapper = this.$sliderWrapper.find('.js-sub-slider-wrapper');

			this.sliderItemsCollection = document.querySelectorAll(`.js-slider-wrapper--${config.name} .b-slider-item`);

			if (!this.sliderState.mobile) {
				// инит слайдеров
				await this.initSlider(this.$sliderWrapper);

				if (this.config.subSlider && this.subSliderWrapper.length) {
					const $slider = $(this.subSliderWrapper).find(this.config.subSlider.sliderClass);

					if ($slider.length) {
						this.subSlider = new this.CSwiper($slider, this.config.subSlider.options);
					}
				}
				// инит слайдеров

				this.initPaginationIndicator(this.$sliderWrapper.find('.js-pagination-indicator'));
				this.initProgressBar(this.$sliderWrapper.find('.js-progress-bar-wrapper'));
				this.initAutoplayIndicator(document.querySelector('.js-progress-bar-wrapper .js-progress-circle'));

				this.setHelperIndex();
				this.swiper.on('slideChangeTransitionStart', $.proxy(this, 'setHelperIndex', true));
				this.swiper.on('slideChangeTransitionEnd', $.proxy(this, 'setAnimatedSlideState'));
			} else {
				// mobile
				this.initMobile();
				this.body.scrollTop = 0;
			}

			if (this.preloader) {
				this.initPreloader();
			}
		}
	}

	initMobile() {
		this.observer = new IntersectionObserver((entries, observer) => {

			entries.forEach(entry => {
				if (entry.isIntersecting && !entry.target.classList.contains('js-is-animated')) {
					entry.target.classList.add('js-is-animated');

					observer.unobserve(entry.target);
				}
			});

		}, {
			root: null,
			rootMargin: '50px',
			threshold: 0.3
		});

		this.sliderItemsCollection.forEach(element => {
			this.observer.observe(element);
		});
	}

	initPreloader() {
		// is-hide-icon
		// const icons_wrapper = document.querySelector('.js-preloader-icons');

		this.loadState = new Promise((resolve, reject) => {
			window.onload = () => {
				resolve();
			};
		})
			.then(() => {
				$(this.preloader).velocity('transition.fadeOut', {
					duration: 1200,
					delay: 1000,
					begin: () => {
						setTimeout(() => {
							this.preloader.classList.add('hide-bg');
							// icons_wrapper.classList.remove('is-hide-icon');
							this.mouse.style.opacity = '1';

							if (!this.sliderState.mobile) {
								this.setAnimatedSlideState();
							}
						}, 500);
					}
				});

			});
	}

	getBulletMarkup(index, className) {
		return `<span class="${className} b-slider-pagination__item js-set-pagination-size"></span>`;
	}

	setAnimatedSlideState() {
		this.$sliderWrapper.find('.js-is-animated').removeClass('js-is-animated');
		this.$sliderWrapper.find(`.js-slider-item.swiper-slide-active`).addClass('js-is-animated');
	}

	initPaginationIndicator(p_wrapper) {
		if (!p_wrapper.length) {
			return false;
		}

		let num = p_wrapper[0].querySelector('.js-pagination-num');
		let total = p_wrapper[0].querySelector('.js-pagination-total');

		num.textContent = `${this.getSwiperState().current + 1}`;
		total.textContent = `${this.getSwiperState().total}`;

		this.swiper.on('slideChangeTransitionStart', () => {
			num.textContent = `${this.getSwiperState().current + 1}`;
		});
	}

	setHelperIndex(mouse = false) {
		// часть проброса вспомогательных классов ----
		const index = this.getSwiperState().current;
		this.body.setAttribute('data-helper', `${index}`);

		const edge = this.getSwiperState().begin ? 'swiperIsStart' : this.getSwiperState().end ? 'swiperIsEnd' : '';
		this.body.setAttribute('data-edge', `${edge}`);
		// часть проброса вспомогательных классов ----

		// состояние мышки-подсказки ----
		if (mouse && this.mouse && this.sliderState.mouseHelper) {
			this.mouse.style.display = 'none';
			this.sliderState.mouseHelper = false;
		}
		// состояние мышки-подсказки ----

		// остановка автоплея сабслайдера
		if (this.subSlider) {
			if (index === 1) {
				this.subSlider.autoplay.start();
			} else {
				this.subSlider.autoplay.stop();
			}
		}
		// остановка автоплея сабслайдера
	}
}

AR.waitComponents(['cPreloader_base'], () => {
	const cSlider_main = new CSlider_main();

	cSlider_main.init();

	AR.pushComponent(cSlider_main, 'cSlider_main');
});
