//конфиг шаблона компонента
const config = require('./config.yaml');
//базовый класс компонента
const CProject = require('../../index.js');
//const CSlider = require('../../../slider/index.js');
//const configSwiper = require('./config-swiper.yaml');

/**
 * Класс CProjects_projects, наследник CProject
 * Реализует логику шаблона projects компонента CProject
 */
class CProjects_clients extends CProject {
	constructor() {
		super();

		//конфиг шаблона компонента
		this.config = config;

		this.$projectsWrapper = null;
	}

	/**
	 * Инициализация компонента
	 */
	init() {
		//TODO заложить вероятность присутствия нескольких одинаковых компонентов на странице
		this.$projectsWrapper = $('#clients');

		if (this.$projectsWrapper.length) {
			this.$shuffleContainer = this.$projectsWrapper.find('[data-shuffle-container]');
			this.$filterTriggers = this.$projectsWrapper.find('.js-trigger');
			this.$filterWrapper = this.$projectsWrapper.find('.b-projects__filter-wrapper');
			this.$buttonMenu = $('.button-memu');
			this.$buttonMenu.on('click', $.proxy(this, 'toggleMenu'));

			this.getShuffleModule()
				.then((Shuffle) => {
					this.shuffleInstance = new Shuffle(this.$shuffleContainer[0], {
						itemSelector: '.js-item',
						group: 'largest',
					});

					this.filterLogic();
				});

			this.initFilterLogic();
		}

		if ($('.body--projects').length) {
			this.replaceMockup();
			$(window).on('resize', $.proxy(this, 'replaceMockup'));
		}
	}

	replaceMockup() {
		const $imageMockup = $('.project__mockup');

		if (window.innerWidth <= 980) {
			$('.project-logo').after($imageMockup).css('position', 'relative');
		}

		if (window.innerWidth > 980) {
			$('.project__bottom-block').after($imageMockup);
		}
	}

	scrollToTop() {
		$('body').velocity('scroll', {
			duration: 300,
			offset: 0,
			easing: 'ease-in-out'
		});
	}

	filterLogic() {
		this.$filterTriggers.on('click', (e) => {
			let $trigger = $(e.currentTarget);
			let filterMask = JSON.parse($trigger.attr('data-filter-group'));

			this.$filterTriggers.removeClass('is-active');

			this.shuffleInstance.filter(filterMask);
			this.shuffleInstance.update();

			if (config.scrollTopOnChange && $(window).scrollTop() > config.minForScrollTop) {
				this.scrollToTop();
			}

			$trigger.addClass('is-active');

			return false;
		});
	}

	toggleMenu() {
		if (!this.$filterWrapper.hasClass('is-active')) {
			this.showMenu();
		} else {
			this.hideMenu();
		}
	}

	showMenu(duration = 400) {
		$.Velocity.animate(this.$filterWrapper, 'slideDown', duration);
		this.$filterWrapper.addClass('is-active');
		this.$buttonMenu.addClass('is-expand');
	}

	hideMenu(duration = 400) {
		$.Velocity.animate(this.$filterWrapper, 'slideUp', duration);
		this.$filterWrapper.removeClass('is-active');
		this.$buttonMenu.removeClass('is-expand');
	}

	initFilterLogic() {
		if (window.innerWidth > 1000 && !this.$filterWrapper.hasClass('is-mobile')) {
			this.showMenu();
		}

		$(window).on('resize', $.proxy(this, 'filterLogicAdaptive'));
	}

	filterLogicAdaptive() {
		if (window.innerWidth <= 1000 && !this.$filterWrapper.hasClass('is-mobile')) {
			this.$filterWrapper.addClass('is-mobile');
			this.$filterTriggers.on('click', $.proxy(this, 'hideMenu'));
			this.hideMenu(0);
		} else if (window.innerWidth > 1000 && this.$filterWrapper.hasClass('is-mobile')) {
			this.$filterWrapper.removeClass('is-mobile');
			this.$filterTriggers.off('click', $.proxy(this, 'hideMenu'));
			this.showMenu(0);
		}
	}

	// replaceButtonAll(a, b) {
	// 	var aNext = $('<div>').insertAfter(a);
	// 	a.insertAfter(b);
	// 	b.insertBefore(aNext);
	// 	aNext.remove();
	// }
}

AR.waitComponents(['cPreloader_base'], () => {
	const cProject_clients = new CProjects_clients();

	cProject_clients.init();

	AR.pushComponent(cProject_clients, 'cProject_clients');
});
